import { c, s, t } from '../val.config';

const item = s.object({
  text: s.string(),
  href: s.string().raw().nullable(),
  newTab: s.boolean(),
});

const footerSchema = s.object({
  contact: s.array(s.array(item)),
  links: s.array(item),
});

export type Footer = t.inferSchema<typeof footerSchema>;

export default c.define('/content/footer.val.ts', footerSchema, {
  links: [
    {
      text: 'LinkedIn',
      href: 'https://no.linkedin.com/company/blankas',
      newTab: true,
    },
    {
      text: 'Instagram',
      href: 'https://www.instagram.com/blankoslo',
      newTab: true,
    },
    {
      text: 'Facebook',
      href: 'https://www.facebook.com/blankoslo',
      newTab: true,
    },
  ],
  contact: [
    [
      {
        text: 'hei@blank.no',
        href: 'mailto:innboks@blank.no',
        newTab: false,
      },
      {
        text: '22 20 40 00',
        href: 'tel:22204000',
        newTab: false,
      },
      {
        text: 'Bli kontaktet',
        href: '/prosjektprat',
        newTab: false,
      },
    ],
    [
      {
        text: 'Torggata 15',
        href: 'https://goo.gl/maps/7nU5pofgdSm',
        newTab: true,
      },
      {
        text: '0181 Oslo',
        href: null,
        newTab: false,
      },
      {
        text: 'Org. nr: 915 433 073',
        href: null,
        newTab: false,
      },
    ],
  ],
});
