const Arrow: React.FC<{
  color?: string;
  className?: string;
}> = ({ className = '', color = 'currentColor' }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      fill="none"
      stroke={color}
      strokeWidth="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4 12h16m-6-6l6 6m-6 6l6-6"
      />
    </svg>
  );
};

export default Arrow;
