import Link from 'next/link';
import SvgIllustration from '../Illustration/SvgIllustration';
import { BlankLogo } from '../SVGs';

interface HeaderProps {
  setFixed?: boolean;
  children?: React.ReactNode | React.ReactNode[];
  withBackgroundColor?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  children,
  setFixed = true,
  withBackgroundColor,
}) => {
  const fixedHeader = setFixed ? 'fixed z-20 top-0' : '';
  return (
    <nav
      className={`flex items-center justify-between w-full h-header px-16 laptop:px-24 ${fixedHeader} overflow-x-clip ${
        withBackgroundColor ? 'bg-blank-black' : ''
      }`}
    >
      <Link
        href="/"
        className="hover:bg-transparent pointer-events-auto hover:text-current"
        aria-label="Hjem til Blank.no"
      >
        <SvgIllustration>
          <BlankLogo className="w-[93px] h-[31px] tablet:w-[105px] tablet:h-[34px]" />
        </SvgIllustration>
      </Link>
      {children}
    </nav>
  );
};

export default Header;
