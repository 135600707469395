import React from 'react';
import BlankLink from '../BlankLink/BlankLinkOld';
import { useVal } from '../../val/val.client';
import footerVal from '../../content/footer.val';
import { BlankRectangle } from '../SVGs';

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  const footerContent = useVal(footerVal);

  return (
    <div className="px-16 pb-16 laptop:px-24 laptop:pb-24">
      <div className="mb-64">
        <BlankRectangle color="var(--light)" />
      </div>
      <div className="flex justify-between flex-col tablet:flex-row gap-64 tablet:items-end">
        <div className="flex flex-col tablet:flex-row gap-64">
          {footerContent.contact.map((column, i) => {
            return (
              <div key={i} className="flex flex-col gap-8">
                {column.map((item, j) => {
                  return (
                    <FooterItem
                      key={j}
                      href={item.href || ''}
                      newTab={item.newTab}
                    >
                      {item.text}
                    </FooterItem>
                  );
                })}
              </div>
            );
          })}
        </div>

        <div className="flex flex-col tablet:flex-row gap-8 tablet:gap-64">
          {footerContent.links.map((item, i) => {
            return (
              <FooterItem key={i} href={item.href || ''} newTab={item.newTab}>
                {item.text}
              </FooterItem>
            );
          })}
        </div>
      </div>
    </div>
  );
};

interface FooterItemProps {
  children: React.ReactNode;
  href: string;
  newTab: boolean;
}

const FooterItem = ({ children, href, newTab }: FooterItemProps) => {
  const className = `flex flex-col content-start w-fit`;

  if (!href) {
    return <div className={className}>{children}</div>;
  }

  return (
    <div className={className}>
      <BlankLink newTab={newTab} href={href}>
        <span className="inline-flex items-start gap-4">
          {children}

          {newTab && (
            <svg
              className="w-16 h-h16 inline-block shrink-0 pt-2"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 17L17 7M7 7h10v10"
              />
            </svg>
          )}
        </span>
      </BlankLink>
    </div>
  );
};

export default Footer;
