import { s, c, t } from '../val.config';

const navigationItemSchema = s.union(
  'type',
  s.object({ type: s.literal('link'), title: s.string(), url: s.string() })
);

export const schema = s.object({
  navigationItems: s.array(navigationItemSchema),
  contactItem: navigationItemSchema,
});

export default c.define('/content/header.val.ts', schema, {
  navigationItems: [
    { type: 'link', url: '/prosjekter', title: 'Arbeider' },
    { type: 'link', url: '/tjenester', title: 'Tjenester' },
    { type: 'link', url: '/om-oss', title: 'Om oss' },
    { type: 'link', url: '/menneskene', title: 'Våre folk' },
    { type: 'link', url: '/jobb', title: 'Jobb ' },
    { type: 'link', url: '/handboka', title: 'Håndboka' },
  ],
  contactItem: { type: 'link', url: '/prosjektprat', title: 'Kontakt' },
});
